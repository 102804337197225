import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Text from "../Text";
import {
  casaConstants,
  ROUTES_PATH,
} from "../../constants/globalConstant";
import { teamsViewCardItems } from "../../utils/casaUtils";
import { getStatusColor } from "../../utils/globalUtils";
import { WebDashboardCardMenu } from "../WebDashboardCardMenu";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Preview } from "../../assets/svgIcons/lsq_preview.svg";
import { changeSelectedHomepage } from "../../reducers/homePageSlice";
import { apiGetWebDashboards, setCloneDashboardData } from "../../reducers/webHomepageSlice";

const WebTeamsViewDashboardCard = ({
  homepage,
  setDialog,
  teamId,
}) => { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getHomepageStatusColor = (status) => {
    let _homepageStatus = "";
    if (
      status === casaConstants.PUBLISHED &&
      homepage?.cloneDashboardId && 
      homepage?.clonedDashboard?.updatedAt !== homepage?.clonedDashboard?.createdAt
    ) {
      _homepageStatus = casaConstants.UNPUBLISHED_CHANGES;
    } else {
      _homepageStatus = status;
    }
    return getStatusColor(_homepageStatus);
  };

  const navigateToDashBoard = () => {
    dispatch(changeSelectedHomepage(homepage.id));
    dispatch(setCloneDashboardData(homepage?.clonedDashboard));
    dispatch(apiGetWebDashboards(homepage.id))
      .then((response) => {
        const dashboardData = response?.payload;
        if (dashboardData) {
          localStorage.setItem(
            "currentDashboardInfo",
            JSON.stringify(dashboardData)
          );
          navigate(ROUTES_PATH.webHomepage);
        }
      })
      .catch((error) => {
        console.error("Error getting dashboard data:", error);
      });
  };

  const previewButtonHandler = () => {
    navigateToDashBoard();
  }

  const homepageStatusColorClass =
    styles[getHomepageStatusColor(homepage?.status)];
  const statusStyleClass = styles["status-style"];
  const combinedClass = `${homepageStatusColorClass} ${statusStyleClass}`;

  return (
    <div
      className={`flex relative ${styles["dashboard-card-container"]}`}
      data-testid="teams-view-dashboard-card"
    >
      <div
        className={`flex align-items-center gap-1 p-3 w-full h-full ${styles["dashboard-card"]}`}
      >
        <div
          className={combinedClass}
        ></div>
        <Text type="T3B" color={"#314361"} className={`ellipsis w-full`}>
          {homepage?.name}
        </Text>
      </div>
      <div
        className={`flex w-full h-full align-items-center p-3 gap-1 justify-content-end absolute visible-on-hover ${styles["dashboard-card-hover"]}`}
      >
        <div
          className="bg-white p-1"
          style={{ borderRadius: "5px", cursor: "pointer" }}
          onClick={previewButtonHandler}
          role="button"
        >
          {          
             (homepage?.status === casaConstants.UNPUBLISHED) || 
             (homepage?.status === casaConstants.PUBLISHED && homepage?.clonedDashboard?.updatedAt !== homepage?.clonedDashboard?.createdAt)
              ? <Edit style={{ width: "1.2rem" }} />
              : <Preview style={{ width: "1.2rem" }} />
          }
        </div>
        <div className="bg-white p-1" style={{ borderRadius: "5px" }}>
          <WebDashboardCardMenu
            items={teamsViewCardItems(homepage?.status)}
            homepage={homepage}
            setDialog={setDialog}
            teamId={teamId}
          />
        </div>
      </div>
    </div>
  );
};

export default WebTeamsViewDashboardCard;
