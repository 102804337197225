import {
  API_ADD_WEB_HOMEPAGE,
  API_ADD_WEB_WIDGET,
  API_DELETE_WEB_DASHBOARD,
  API_BULK_DELETE_WEB_DASHBOARDS,
  API_DELETE_WEB_WIDGET,
  API_GET_ALL_WEB_DASHBOARDS,
  API_GET_WEB_TEAMS_DASHBOARDS,
  API_GET_WEB_WIDGET_TEMPLATE,
  API_PUBLISH_WEB_DASHBOARD,
  API_UNPUBLISH_WEB_DASHBOARD,
  API_UPDATE_DASHBOARD_TEAM_ASSIGNMENT,
  API_UPDATE_WEB_HOMEPAGE,
  API_UPDATE_WEB_WIDGET,
  API_GET_WEB_DASHBOARD,
  API_GET_WEB_HOMEPAGE_TASK_COUNT,
  API_EDIT_WEB_HOMEPAGE,
  API_POST_CLONE_WEB_HOMEPAGE,
  API_DELETE_CLONE_WEB_DASHBOARD,
  API_PUBLISH_CLONE_WEB_DASHBOARD,
  API_GET_WEB_TEAMS_DASHBOARDS_V2
} from "../api/webHompageConfigurationServices";
import {
  casaWebConstants,
  toastMessageConstants,
  defaultWidgetConfigurations,
  coverPageWidget,
  statusParameters
} from "../constants/globalConstant";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


const constructPayload = (widget) => {
  const { config } = widget;
  const { taskStatus, taskTypes } = config;

  let filters = {
    lookupName: "OwnerId",
    lookupValue: "2dd52112-461e-11ed-ac1c-02930afc2f3c",
    statusCode: -1,
    searchText: null,
    includeOverdue: false,
    taskRetrievalDataSource: 0,
    includeOnlyOverdue: false,
    includeCanUpdate: false,
    completedOnFrom: null,
    completedOnTo: null,
  };

  // Update filters based on taskStatus
  if (taskStatus && taskStatus.id) {
    const statusParams = statusParameters[taskStatus.id];
    if (statusParams) {
      filters = { ...filters, ...statusParams };
    }
  }

  // Update filters based on taskTypes
  if (taskTypes) {
    if (Array.isArray(taskTypes.category) && taskTypes.category.length > 0) {
      filters.taskTypeId = taskTypes.category[0];
    } else {
      const taskTypeIds = [];
      if (taskTypes.appointmentId) {
        taskTypeIds.push(taskTypes.appointmentId);
      }
      if (taskTypes.todoId) {
        taskTypeIds.push(taskTypes.category);
      }
      filters.taskTypeId = [...taskTypes.appointmentId,...taskTypes.todoId].join(',');
    }
  }

  return {
    pageIndex: 0,
    pageSize: 25,
    sortField: null,
    sortDirection: 0,
    includeCsv: null,
    excludeCsv: null,
    filters,
  };
};

const initialState = {
  widgets: [],
  dashboardById: {},
  teamWebDashboards: {},
  teamWebDashboardsV2: {}, // Storing V2 API Response
  widgetMetaData: {},
  entityMetaData: {},
  selectedWebHomepage: {},
  selectedWidgetLanguage: null,
  layout: [],
  visiblePanel: casaWebConstants.LEFT,
  isWebHomepageLoading: false,
  isWidgetsLaoding: false,
  toastMessages: [],
  publish: {
    isPublishLoading: false,
    isPublished: false,
  },
  coverPageThumbnail: null,
  coverPageData:{
    heading:'',
    description:'',
    selectedColor: '',
    customRadio: '',
    selectedImage:''
  },
  dialog: {
    state: false,
    widgetId: null,
  },
  selectedWidgetId:null,
  currentSavedLayout:[],
  hasUnsavedChanges:false,

  renderLayoutConfig: "clone", // Render "initial" || "clone" configuration
  cloneDashboardData: null,  // Cloned when editing published dashboard
  initialDashboardData: null, // Initial dashboard data

  casaWebLimits: null,
  signedUrlApi:{
    isLoading: false
  },

  iconLauncherItems: null
};


export const apiGetAllWebDashboards = createAsyncThunk(
  "webHomepage/apiGetAllWebDashboards",
  async (_, thunkAPI) => {
    const response = await API_GET_ALL_WEB_DASHBOARDS();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const apiGetWebDashboards = createAsyncThunk(
  "webHomepage/apiGetWebDashboards",
  async (dashboardId, thunkAPI) => {

   const response = await API_GET_WEB_DASHBOARD(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data; //layoutJSON//
  }
);


// For FETCHING a cloned Published Web-Dashboard Layout
export const apiGetCloneWebDashboards = createAsyncThunk(
  "webHomepage/apiGetCloneWebDashboards",
  async (dashboardId, thunkAPI) => {
   const response = await API_GET_WEB_DASHBOARD(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data; //layoutJSON//
  }
);


// For CREATING a clone for Published Web-Dashboard
export const apiPostCloneWebDashboards = createAsyncThunk(
  "webHomepage/apiPostCloneWebDashboards",
  async (dashboardId, thunkAPI) => {
   const response = await API_POST_CLONE_WEB_HOMEPAGE(dashboardId, { isDraft: true });
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data; //layoutJSON//
  }
);

  export const apiGetAllTeamsWebDashboards = createAsyncThunk(
      "webHomepage/apiGetAllTeamsWebDashboards",
      async (_, thunkAPI) => {
        const response = await API_GET_WEB_TEAMS_DASHBOARDS();
        if (response?.status !== 200) {
          const errorMessage =
            response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
          return thunkAPI.rejectWithValue(errorMessage);
        }
        return response.data;
      }
    );

export const apiGetAllTeamsWebDashboardsV2 = createAsyncThunk(
  "webHomepage/apiGetAllTeamsWebDashboardsV2",
  async (_, thunkAPI) => {
    const response = await API_GET_WEB_TEAMS_DASHBOARDS_V2();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiAddWebHomepage = createAsyncThunk(
  "webHomepage/apiAddHomepage",
  async ({ postBody }, thunkAPI) => {
    const response = await API_ADD_WEB_HOMEPAGE(postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiUpdateWebHomepage = createAsyncThunk(
  "webHomepage/apiUpdateWebHomepage",
  async ({ dashboardId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_WEB_HOMEPAGE(dashboardId, postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiRenameWebHomepage = createAsyncThunk(
  "webHomepage/apiRenameWebHomepage",
  async ({ dashboardId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_WEB_HOMEPAGE(dashboardId, postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiEditWebHomepage = createAsyncThunk(
  "webHomepage/apiEditWebHomepage",
  async ({ id, postBody }, thunkAPI) => {
    const response = await API_EDIT_WEB_HOMEPAGE(id, postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const apiDeleteWebHomepage = createAsyncThunk(
  "webHomepage/apiDeleteWebHomepage",
  async (dashboardId, thunkAPI) => {
    const response = await API_DELETE_WEB_DASHBOARD(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);


export const apiDeleteCloneWebHomepage = createAsyncThunk(
  "webHomepage/apiDeleteCloneWebHomepage",
  async (dashboardId, thunkAPI) => {
    const response = await API_DELETE_CLONE_WEB_DASHBOARD(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiBulkDeleteWebHomepage = createAsyncThunk(
  "webHomepage/apiBulkDeleteWebHomepage",
  async (dashboardIds, thunkAPI) => {
    const response = await API_BULK_DELETE_WEB_DASHBOARDS(dashboardIds);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const publishWebHomePage = createAsyncThunk(
  "webHomepage/publishWebHomePage",
  async ({ dashboardId, postBody }, thunkAPI) => {
    const response = await API_PUBLISH_WEB_DASHBOARD(dashboardId, postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }

);
export const publishCloneWebHomePage = createAsyncThunk(
  "webHomepage/publishCloneWebHomePage",
  async ({ dashboardId, postBody }, thunkAPI) => {

    const response = await API_PUBLISH_CLONE_WEB_DASHBOARD(dashboardId, postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const unpublishWebHomePage = createAsyncThunk(
  "webHomepage/unpublishWebHomePage",
  async (dashboardId, thunkAPI) => {
    const response = await API_UNPUBLISH_WEB_DASHBOARD(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const updateWebDashboardTeamAssignment = createAsyncThunk(
  "webHomepage/updateWebDashboardTeamAssignment",
  async ({ dashboardId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_DASHBOARD_TEAM_ASSIGNMENT(
      dashboardId,
      postBody
    );
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const getWebWidgetTemplate = createAsyncThunk(
  "webHomepage/getWebWidgetTemplate",
  async (_, thunkAPI) => {
    const response = await API_GET_WEB_WIDGET_TEMPLATE();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    //return data;
    const widgetsWithDefaults = data.map(widget => ({
      ...widget,
       width: defaultWidgetConfigurations[widget.id]?.width || 4,
       height: defaultWidgetConfigurations[widget.id]?.height || 4,
       minW: defaultWidgetConfigurations[widget.id]?.minW || 2,
        maxW: defaultWidgetConfigurations[widget.id]?.maxW || 12,
        minH: defaultWidgetConfigurations[widget.id]?.minH || 2,
        maxH: defaultWidgetConfigurations[widget.id]?.maxH || 12,
    }));
    return widgetsWithDefaults;
  }
);

export const apiAddWebWidget = createAsyncThunk(
  "webHomepage/apiAddWebWidget",
  async ({ dashboardId, payload }, thunkAPI) => {
  
    const response = await API_ADD_WEB_WIDGET(dashboardId, payload);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
 
    return data;
  }
);

export const apiUpdateWebWidget = createAsyncThunk(
  "webHomepage/apiUpdateWebWidget",
  async ({  id, payload, dashboardId }, thunkAPI) => {
    const response = await API_UPDATE_WEB_WIDGET( id, payload, dashboardId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }

    thunkAPI.dispatch(updateWebWidget({ id, config: data.config }));
    return data;
  }
);

export const apiDeleteWebWidget = createAsyncThunk(
  "webHomepage/apiDeleteWebWidget",
  async ({ dashboardId,widgetId }, thunkAPI) => {
    const response = await API_DELETE_WEB_WIDGET(dashboardId, widgetId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
  
);
export const apiGetTaskCount = createAsyncThunk(
  "webHomepage/apiGetTaskCount",
  async ({ postBody }, thunkAPI) => {
    const response = await API_GET_WEB_HOMEPAGE_TASK_COUNT(postBody);
    if (response?.status !== 201) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const apiFetchAndMergeWidgetsData = createAsyncThunk(
  "webHomepage/apiFetchAndMergeWidgetsData",
  async (initialDashboardData, thunkAPI) => {
    try {
      const widgets = initialDashboardData.layout.widgets;

      const widgetDataPromises = widgets.map(async (widget) => {
        let updatedWidget = { ...widget };

        if (widget.config && widget.config.entity && widget.config.entity.id) {
          const postBody = constructPayload(widget);
          const additionalData = await thunkAPI.dispatch(apiGetTaskCount({ postBody })).unwrap();

          updatedWidget = {
            ...widget,
            config: {
              ...widget.config,
              count: additionalData?.count
            }
          };

          if (widget.config.maxCount === undefined) {
            // Prepare additional data for max count API call
            const maxCountPayload = {
              ...postBody,
              filters: {
                ...postBody.filters,
                date: new Date().toISOString().split('T')[0] // Adding today date filter
              }
            };

            const maxCountData = await thunkAPI.dispatch(apiGetTaskCount({ postBody: maxCountPayload })).unwrap();

            updatedWidget.config = {
              ...updatedWidget.config,
              maxCount: maxCountData?.count
            };
          }
        }

        return updatedWidget;
      });

      const updatedWidgets = await Promise.all(widgetDataPromises);
      const updatedDashboardData = {
        ...initialDashboardData,
        layout: {
          ...initialDashboardData.layout,
          widgets: updatedWidgets,
        },
      };

      return updatedDashboardData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



const webHomepageSlice = createSlice({
  name: "webHomepage",
  initialState,
  reducers: {
    setUnsavedChanges(state, action) {
      state.hasUnsavedChanges = action.payload;
    },
    resetSelectedWidget(state) {
      state.selectedWidgetId = null;
    },
    setSelectedWidgetId(state,action) {
      state.selectedWidgetId = action.payload;
    },
    setDeleteDialog(state, action) {
      state.dialog = action.payload;
    },
    addToastMessageFromWebHomepage(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessagesFromWebHomepage(state) {
      state.toastMessages.shift();
    },
    changeSelectedWidgetLanguage(state, action) {
      state.selectedWidgetLanguage = action.payload;
    },
    setVisiblePanel(state, action) {
      state.visiblePanel = action.payload;
    },
    addWebWidget(state, action) {
      const { id, widgetType, config,width, height,minW, maxW, minH, maxH } = action.payload;
      const layout = [...state.layout];
      if (layout.find(widget => widget.id === id)) {
        console.error('Duplicate widget ID found:', id); // Debug log
        return;
      }
      let x = 0, y = 0;

      if (layout.length > 0) {
        const lastWidget = layout[layout.length - 1];
        x = lastWidget.x + lastWidget.w;
        y = lastWidget.y;

        if (x + 4 > 12) { // if it exceeds the number of columns, move to the next row
          x = 0;
          y = lastWidget.y + lastWidget.h;
        }
      }

      layout.push({
        id,
        widgetType,
        config,
        x: x,
        y: y,
        w: width || config?.width || 4, // default width
        h: height || config?.height || 4,
        minW,
        maxW,
        minH,
        maxH,
      });
      state.layout = layout;
    },
    deleteWebWidget(state, action) {
      const { id } = action.payload;
      const layout = [...state.layout];
      const updatedLayout = layout.filter(
        (widget) => widget.id !== id
      );
      state.layout = updatedLayout;
    },
    updateWebWidget(state, action) {
      const { id, config } = action.payload;
      const layout = [...state.layout];
      const updatedWidget = layout.map((widget) =>
        widget.id === id ? { ...widget, config: config } : widget
      );
      state.layout = updatedWidget;
    },
    updateEntityMetaData(state, action) { 
      state.entityMetaData = action.payload;
    },
    updateLayout(state, action) {
      let updatedLayout = action.payload.map(item => {
        const existingWidget = state.layout.find(widget => widget.id === item.i);
        return {
          id: item.i,
          widgetType: existingWidget ? existingWidget.widgetType : undefined, // Maintain widget type
          config: existingWidget ? existingWidget.config : {}, // Maintain existing configuration
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          minW: existingWidget ? existingWidget.minW : undefined,
          maxW: existingWidget ? existingWidget.maxW : undefined,
          minH: existingWidget ? existingWidget.minH : undefined,
          maxH: existingWidget ? existingWidget.maxH : undefined,
        };
      });
      state.layout = updatedLayout;
    },
    saveLayout(state) {
      state.savedLayout = [...state.currentLayout];
    },
    cancelLayout(state) {
      state.currentLayout = [...state.savedLayout];
    },
    resetWebHomepage(state) {
      state.selectedWebHomepage = {};
      state.selectedWidgetLanguage = null;
      state.layout = [];
      state.publish = initialState.publish;
      state.renderLayoutConfig = "clone" // Render "initial" || "clone" configuration
      state.cloneDashboardData = null  // Cloned when editing published dashboard
      state.initialDashboardData = null // Initial dashboard data
      state.coverPageData = null
    },
    updateCoverPage(state, action) {
      state.coverPageData = action.payload;
    },

    setCloneDashboardData(state, action) {
      state.selectedWebHomepage.cloneDashboardId = action.payload?.id ?? null;
      state.cloneDashboardData = action?.payload ?? null;
    },

    updateRenderLayoutConfig(state, action) {
      state.renderLayoutConfig = action.payload; // "initial" || "clone"
      let layoutConfig = action.payload === "initial" ? state.initialDashboardData : state.cloneDashboardData;

      if (layoutConfig?.layout?.widgets) {
        state.layout = layoutConfig.layout.widgets;
        state.currentSavedLayout= layoutConfig.layout.widgets;
        state.publish.isPublished = state.initialDashboardData.status;
        state.coverPageData = layoutConfig.layout.coverPage;
      } else {
        state.layout = [];
        state.currentSavedLayout=[]
        state.coverPageData ={}// action?.payload?.layout ? {}:initialState.coverPageData
      }
    },

    setCasaWebLimits(state, action) {
      state.casaWebLimits = action.payload;
    },
    setSignedUrlApiLoader(state, action) {
      state.signedUrlApi.isLoading = action.payload
    },
    setCoverPageThumbnail(state, action) {
      state.coverPageThumbnail = action.payload
    },
    setIconLauncherItems(state, action) {
      state.iconLauncherItems = action.payload
    },
    updateBannerWidgetInLayout(state, action) {
      state.layout = state.layout.map((widget)=> {
        if (widget.id === state.selectedWidgetLanguage.id) {
          return {
           ...widget,
            config: {
             ...widget.config,
              showFrame: action.payload
            }
          };
        }
        return widget;
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase("homepage/apiUpdateWebTeamsDashboardOrder/fulfilled", (state, action) => {
      const updatedTeam = action.payload;

      let clonedTeamDashboards = { ...state.teamWebDashboardsV2 };

      // Check if the updated team is the default dashboard
      if (clonedTeamDashboards.defaultDashboards?.teamId === updatedTeam.teamId) {
        clonedTeamDashboards.defaultDashboards = updatedTeam;
      }
      else {
        // Update the team dashboard if it's not the default
        clonedTeamDashboards.teamDashboards = clonedTeamDashboards.teamDashboards.map(team =>
          team.teamId === updatedTeam.teamId ? updatedTeam : team
        );
      }

      state.teamWebDashboardsV2 = clonedTeamDashboards;
    });

    builder.addCase(apiGetAllWebDashboards.pending, (state) => {
      state.isWebHomepageLoading=true
    });
    builder.addCase(apiGetAllWebDashboards.fulfilled, (state, action) => {
      const dashboards = action.payload;
      const dashboardById = Object.fromEntries(
        dashboards?.map((dashboard) => {
          return [dashboard.id, dashboard];
        })
      );
      state.dashboardById = dashboardById;
      state.isWebHomepageLoading=false
    });

    // Handling cases Creating a published template clone
    builder.addCase(apiPostCloneWebDashboards.pending, (state) => {
      state.isWebHomepageLoading=true
    });
    builder.addCase(apiPostCloneWebDashboards.fulfilled, (state, action) => {
      state.isWebHomepageLoading=false
      state.cloneDashboardData = action.payload;
      state.selectedWebHomepage.cloneDashboardId = action.payload.id;
    })
   

    // Handling cases Fetching a published template clone
    builder.addCase(apiGetCloneWebDashboards.pending, (state) => {
      state.isWebHomepageLoading=true
    });
    builder.addCase(apiGetCloneWebDashboards.fulfilled, (state, action) => {
      state.isWebHomepageLoading=false
      state.cloneDashboardData = action.payload;
      
      let layoutConfig = (state.renderLayoutConfig === "clone" && state?.selectedWebHomepage?.cloneDashboardId) ? state.cloneDashboardData : state.initialDashboardData;
      if (layoutConfig?.layout?.widgets) {
        state.layout = layoutConfig.layout.widgets;
        state.currentSavedLayout= layoutConfig.layout.widgets;
        state.publish.isPublished = state?.initialDashboardData?.status || casaWebConstants.PUBLISHED;
        state.coverPageData = layoutConfig.layout.coverPage;
      } else {
        state.layout = [];
        state.currentSavedLayout=[]
        state.coverPageData ={}// action?.payload?.layout ? {}:initialState.coverPageData
      }
    })


    // Handling cases for discarding cloned dashboard
    builder.addCase(apiDeleteCloneWebHomepage.pending, (state) => {
      state.isWebHomepageLoading=true
    });
    builder.addCase(apiDeleteCloneWebHomepage.fulfilled, (state, action) => {
      state.isWebHomepageLoading=false
      state.cloneDashboardData = null;
      state.initialDashboardData = action.payload
      state.selectedWebHomepage.cloneDashboardId = null;

      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_DISCARD_CHANGES_SUCCESS,
      });
    })
    builder.addCase(apiDeleteCloneWebHomepage.rejected, (state) => {
      state.isWebHomepageLoading=false
    })



    builder.addCase(apiGetWebDashboards.pending, (state) => {
      state.isWebHomepageLoading = true;
    })

    builder.addCase(apiGetWebDashboards.fulfilled, (state, action) => {
      state.isWebHomepageLoading = false;
      state.initialDashboardData = action.payload;
      state.selectedWebHomepage = { cloneDashboardId: action.payload.cloneDashboardId, name: action.payload.name, id: action.payload.id};
      if (action?.payload?.layout?.widgets) {
        state.layout = action.payload.layout.widgets;
        state.currentSavedLayout= action.payload.layout.widgets;
        state.publish.isPublished = action.payload.status;
        state.coverPageData = action.payload.layout.coverPage;
      } else {
        state.layout = [];
        state.currentSavedLayout=[]
        state.coverPageData ={}// action?.payload?.layout ? {}:initialState.coverPageData
      }

      // Dispatch the background fetch for additional widget data
     // thunkAPI.dispatch(apiFetchAndMergeWidgetsData(action.payload));
    });
    builder.addCase(apiFetchAndMergeWidgetsData.fulfilled, (state, action) => {
      state.selectedWebHomepage = { name: action.payload.name, id: action.payload.id };
      if (action?.payload?.layout?.widgets) {
         state.layout = action.payload.layout.widgets;
      }
    });
    builder.addCase(apiFetchAndMergeWidgetsData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
   
    builder.addCase(apiGetAllTeamsWebDashboards.fulfilled, (state, action) => {
      state.teamWebDashboards = action.payload;
    });
    builder.addCase(apiGetAllTeamsWebDashboardsV2.fulfilled, (state, action) => {
      state.teamWebDashboardsV2 = action.payload;
    });
    builder.addCase(publishWebHomePage.fulfilled, (state, action) => {
      const data = action.payload;
      const teamWebDashboards = { ...state.teamWebDashboards };
      data.forEach((dashboard) => {
        const { teamId, dashboardId } = dashboard;
        teamWebDashboards[teamId] = teamWebDashboards[teamId] || [];
        teamWebDashboards[teamId].push(dashboardId);
      });
      state.teamWebDashboards = teamWebDashboards;
      state.publish.isPublished = "published";
      state.publish.isPublishLoading = false;
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_PUBLISH_SUCCESS,
      });
    });
    
    builder.addCase(publishWebHomePage.rejected, (state) => {
      state.publish.isPublishLoading = false;
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: toastMessageConstants.WEB_LAYOUT_PUBLISH_ERROR,
      });
    });
    builder.addCase(publishWebHomePage.pending, (state) => {
      state.publish.isPublishLoading = true;
      state.isWebHomepageLoading = true;
    });

    builder.addCase(publishCloneWebHomePage.pending, (state) => {
      state.publish.isPublishLoading = true;
      state.isWebHomepageLoading = true;
    });

    builder.addCase(publishCloneWebHomePage.fulfilled, (state) => {    
      state.publish.isPublished = "published";
      state.publish.isPublishLoading = false;
      state.isWebHomepageLoading = false;

      state.cloneDashboardData = null;      
      state.selectedWebHomepage.cloneDashboardId = null;
      state.renderLayoutConfig = "clone";

      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_PUBLISH_SUCCESS,
      });
    });

    builder.addCase(publishCloneWebHomePage.rejected, (state) => {
      state.publish.isPublishLoading = false;
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: toastMessageConstants.WEB_LAYOUT_PUBLISH_ERROR,
      });
    });
    

    builder.addCase(unpublishWebHomePage.fulfilled, (state) => {
      state.publish.isPublished = "unpublished";
      state.isWebHomepageLoading = false;
      state.selectedWebHomepage.cloneDashboardId = null;
      state.selectedWebHomepage.cloneDashboardData = null;
      state.renderLayoutConfig = "initial";
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_UNPUBLISH_SUCCESS,
      });
    });
    builder.addCase(unpublishWebHomePage.pending, (state) => {
      state.isWebHomepageLoading = true;
    });
    builder.addCase(unpublishWebHomePage.rejected, (state) => {
      state.publish.isPublished = "published";
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_UNPUBLISH_ERROR,
      });
    });
    builder.addCase(
      updateWebDashboardTeamAssignment.fulfilled,
      (state, action) => {
        const { addTeams, deleteTeams } = action.payload;
        const teamWebDashboards = { ...state.teamWebDashboards };
        addTeams.forEach((dashboard) => {
          const { teamId, dashboardId } = dashboard;
          teamWebDashboards[teamId] = teamWebDashboards[teamId] || [];
          teamWebDashboards[teamId].push(dashboardId);
        });
        deleteTeams.forEach((dashboard) => {
          const { teamId, dashboardId } = dashboard;
          if (teamWebDashboards[teamId]) {
            teamWebDashboards[teamId] = teamWebDashboards[teamId].filter(
              (id) => id !== dashboardId
            );
          }
        });
        state.teamWebDashboards = teamWebDashboards;
      }
    );
    builder.addCase(apiAddWebHomepage.fulfilled, (state, action) => {
   
      state.selectedWebHomepage = action.payload;
      state.isWebHomepageLoading = false;
    });
    builder.addCase(apiAddWebHomepage.pending, (state) => {
      state.isWebHomepageLoading = true;
    });
    builder.addCase(apiAddWebHomepage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isWebHomepageLoading = false;
    });
    builder.addCase(apiUpdateWebHomepage.fulfilled, (state, action) => {
      if(state.renderLayoutConfig === "clone" && state?.selectedWebHomepage?.cloneDashboardId) {
        state.cloneDashboardData = action.payload;
      }
      else{
        state.selectedWebHomepage = action.payload;
      }
      
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_SAVE_SUCCESS,
      });
    });

    builder.addCase(apiRenameWebHomepage.fulfilled, (state, action) => {
      state.selectedWebHomepage.name = action.payload?.name;      
      state.isWebHomepageLoading = false;

      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_SAVE_SUCCESS,
      });
    });

    builder.addCase(apiRenameWebHomepage.pending, (state) => {
      state.isWebHomepageLoading = true;
    });

    builder.addCase(apiRenameWebHomepage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.WEB_LAYOUT_SAVE_ERROR,
      });
      state.isWebHomepageLoading = false;
    });

    builder.addCase(apiUpdateWebHomepage.pending, (state) => {
      state.isWebHomepageLoading = true;
    });
    builder.addCase(apiUpdateWebHomepage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.WEB_LAYOUT_SAVE_ERROR,
      });
      state.isWebHomepageLoading = false;
    });
    builder.addCase(apiEditWebHomepage.fulfilled, (state, action) => {
      const temp = { ...state.dashboardById };
      const thumbnailUri=temp[action.payload.id]?.thumbnailUri
      temp[action.payload.id]={...action.payload,thumbnailUri};
      state.dashboardById=temp
      state.selectedWebHomepage = action.payload;
      state.isWebHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_SAVE_SUCCESS,
      });
    });
    builder.addCase(apiEditWebHomepage.pending, (state) => {
      state.isWebHomepageLoading = true;
    });
    builder.addCase(apiEditWebHomepage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.WEB_LAYOUT_SAVE_ERROR,
      });
      state.isWebHomepageLoading = false;
    });
    builder.addCase(getWebWidgetTemplate.fulfilled, (state, action) => {      
      let widgets = [...action.payload,coverPageWidget];
      //let widgets = action.payload
      const widgetMetaData = widgets?.reduce((acc, widget) => {
        acc[widget.id] = widget.configuration;
        return acc;
      }, {});
      state.widgets = widgets;
      state.widgetMetaData = widgetMetaData
      state.isWidgetsLaoding = false;
    });
    builder.addCase(getWebWidgetTemplate.rejected, (state, action) => {
      state.isWidgetsLaoding = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getWebWidgetTemplate.pending, (state) => {
      state.isWidgetsLaoding = true;
    });
    builder.addCase(apiGetTaskCount.fulfilled, (state, action) => {
   
      state.selectedWebHomepage = action.payload;
    });
    builder.addCase(apiGetTaskCount.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });

    });
    builder.addCase(apiBulkDeleteWebHomepage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    })
  },
});

export const {
  changeSelectedWidgetLanguage,
  addWebWidget,
  deleteWebWidget,
  updateWebWidget,
  setVisiblePanel,
  addToastMessageFromWebHomepage,
  resetToastMessagesFromWebHomepage,
  updateEntityMetaData,
  updateLayout,
  resetWebHomepage,
  updateCoverPage,
  setDeleteDialog,
  resetSelectedWidget,
  setSelectedWidgetId,
  saveLayout,
  currentSavedLayout,
  setUnsavedChanges,
  updateRenderLayoutConfig,
  setCloneDashboardData,
  setCasaWebLimits,
  setSignedUrlApiLoader,
  setCoverPageThumbnail,
  setIconLauncherItems,
  updateBannerWidgetInLayout
} = webHomepageSlice.actions;

export default webHomepageSlice.reducer;
