import cloneDeep from "lodash.clonedeep";
import { casaConstants } from "../constants/globalConstant";
import { ReactComponent as Dashboard } from "../assets/svgIcons/dashboard.svg";
import { ReactComponent as DashboardFilled } from "../assets/svgIcons/dashboard_filled.svg";
import { ReactComponent as TeamsFilled } from "../assets/svgIcons/lsq_teams_filled.svg";
import { ReactComponent as Teams } from "../assets/svgIcons/lsq_teams.svg";

export const sortDashboards = (
  dashboards = [],
  sortParameter = "",
  dashboardsMappedWithId = {}
) => {
  let sortFunction;

  if(sortParameter === casaConstants.ALPHABETICAL){
    sortFunction = (dashboardId1, dashboardId2) => {
      const dashboard1 =
        dashboardsMappedWithId[dashboardId1].homepageName ||
        dashboardsMappedWithId[dashboardId1].name;
      const dashboard2 =
        dashboardsMappedWithId[dashboardId2].homepageName ||
        dashboardsMappedWithId[dashboardId2].name;
      return dashboard1
        ?.toLowerCase()
        ?.localeCompare(dashboard2?.toLowerCase());
    };
  }
  else {
    sortFunction = (dashboardId1, dashboardId2) => {
      const dashboard1 = dashboardsMappedWithId[dashboardId1].updatedAt;
      const dashboard2 = dashboardsMappedWithId[dashboardId2].updatedAt;
      return new Date(dashboard2) - new Date(dashboard1);
    };
  }

  return dashboards.sort(sortFunction);
};

export const searchDashboards = (
  dashboards = [],
  searchParameter='',
  dashboardsMappedWithId={},
  key = "homepageName"
) => {
  if (searchParameter.trim() === "") return dashboards;
  const searchedDashboards = dashboards.filter((dashboardId) =>
    dashboardsMappedWithId[dashboardId]?.[key]
      .toLowerCase()
      .includes(searchParameter.trim().toLowerCase())
  );
  return searchedDashboards;
};

export const filterDashboards = (
  dashboards = [],
  filterParameter = "",
  dashboardsMappedWithId = {}
) => {
  let filterFunction;
  switch (filterParameter) {
    case casaConstants.PUBLISHED:
      filterFunction = (dashboardId) =>
        dashboardsMappedWithId[dashboardId]?.status === casaConstants.PUBLISHED;
      break;
    case casaConstants.UNPUBLISHED:
      filterFunction = (dashboardId) =>
        dashboardsMappedWithId[dashboardId]?.status ===
        casaConstants.UNPUBLISHED;
      break;
    case casaConstants.DRAFT:
      filterFunction = (dashboardId) =>
        dashboardsMappedWithId[dashboardId]?.status === casaConstants.DRAFT;
      break;
    case casaConstants.FAVOURITE:
      filterFunction = (dashboardId) =>
        dashboardsMappedWithId[dashboardId]?.isFavourite === true;
      break;
    default:
      filterFunction = (dashboardId) =>
        dashboardsMappedWithId[dashboardId]?.status;
  }
  dashboards = dashboards.filter(filterFunction);
  return dashboards;
};

export const searchTeams = (
  teamDashboards = [],
  searchParameter = "",
  teamsMappedWithTeamId = {}
) => {
  if (searchParameter.trim() === "") return teamDashboards;
  let searchedTeams = teamDashboards.filter(({ teamId }) => {
    if (teamsMappedWithTeamId[teamId] === undefined) return false;
    return teamsMappedWithTeamId[teamId]?.label
      ?.trim()
      .toLowerCase()
      .includes(searchParameter.trim().toLowerCase());
  });
  return searchedTeams;
};

export const sortTeams = (
  teamDashboards = [],
  sortParameter = "",
  teamsMappedWithTeamId = {}
) => {
  const cloneTeamDashboards = [...teamDashboards];
  let sortFunction;
  if (sortParameter === casaConstants.ALL)
    sortFunction = (team1, team2) =>
      teamsMappedWithTeamId[team1.teamId]?.label
        .toLowerCase()
        .localeCompare(
          teamsMappedWithTeamId[team2.teamId]?.label.toLowerCase()
        );
  else
    sortFunction = (team1, team2) => {
      return new Date(team2.updatedAt) - new Date(team1.updatedAt);
    };
  return cloneTeamDashboards.sort(sortFunction);
};

export const filterTeamDashboards = (
  teamDashboards,
  dashboardsMappedWithId,
  filterParameter
) => {
  const cloneTeamDashboards = cloneDeep(teamDashboards);
  cloneTeamDashboards.forEach((team) => {
    const newFilteredHomepages = filterDashboards(
      team.homepages,
      filterParameter,
      dashboardsMappedWithId
    );
    team.homepages = [...newFilteredHomepages];
  });
  const newTeamsDashboards = cloneTeamDashboards.filter(
    (team) => team?.homepages?.length !== 0
  );
  return newTeamsDashboards;
};

export const casaTabs = [
  {
    displayIcon: <Teams />,
    activeIcon: <TeamsFilled />,
    tooltip: casaConstants.TEAMS,
    "data-testid": "teams-view-tab",
  },
  {
    displayIcon: <Dashboard />,
    activeIcon: <DashboardFilled />,
    tooltip: casaConstants.HOMEPAGES,
    "data-testid": "dashboard-view-tab",
  },
];

export const dashboardViewFilterItems = [
  {
    id: casaConstants.ALL,
    label: casaConstants.ALL,
  },
  {
    id: casaConstants.FAVOURITE,
    label: casaConstants.FAVOURITE,
  },
  {
    id: casaConstants.PUBLISHED,
    label: casaConstants.PUBLISHED,
  },
  {
    id: casaConstants.UNPUBLISHED,
    label: casaConstants.UNPUBLISHED,
  },
  {
    id: casaConstants.DRAFT,
    label: casaConstants.DRAFT,
  },
];

export const teamsViewFilterItems = [
  {
    id: casaConstants.ALL,
    label: casaConstants.ALL,
  },
  {
    id: casaConstants.FAVOURITE,
    label: casaConstants.FAVOURITE,
  },
  {
    id: casaConstants.PUBLISHED,
    label: casaConstants.PUBLISHED,
  },
  {
    id: casaConstants.UNPUBLISHED,
    label: casaConstants.UNPUBLISHED,
  },
];

export const dashboardViewSortItems = [
  {
    id: casaConstants.RECENTLY_MODIFIED,
    label: casaConstants.RECENTLY_MODIFIED,
  },
  {
    id: casaConstants.ALPHABETICAL,
    label: casaConstants.ALPHABETICAL,
  },
];

export const teamsViewSortItems = [
  {
    id: casaConstants.RECENTLY_MODIFIED,
    label: casaConstants.RECENTLY_MODIFIED,
  },
  {
    id: casaConstants.ALL,
    label: casaConstants.ALL,
  },
];

export const generateTeamAssignmentPostData = (
  existingTeamAssigned,
  currentTeamAssigned,
  homepageName,
  isWeb = false
) => {
  const existingTeamsAssigned = existingTeamAssigned.teamAssigned;
  const currentTeamsAssigned = Object.keys(currentTeamAssigned.teamAssigned);
  let addedTeamsIds = [],
    deletedTeamIds = [];
  currentTeamsAssigned.forEach((teamId) => {
    if (existingTeamsAssigned.includes(teamId) === false) {
      addedTeamsIds.push(teamId);
    }
  });

  existingTeamsAssigned.forEach((teamId) => {
    if (currentTeamsAssigned.includes(teamId) === false) {
      deletedTeamIds.push(teamId);
    }
  });
  if (isWeb)
    return {
      isDefault: !!currentTeamAssigned.isDefault,
      addTeams: addedTeamsIds,
      deleteTeams: deletedTeamIds,
      homepageName: homepageName,
    };
  const postBody = {
    isDefault: currentTeamAssigned.isDefault,
    addTeamIds: addedTeamsIds,
    deleteTeamIds: deletedTeamIds,
    homepageName: homepageName,
  };

  return postBody;
};

export const generateTeamAssignmentWebHomepagePostData = (
  existingTeamAssigned,
  currentTeamAssigned,
  orgCode
) => {
  const existingTeamsAssigned = existingTeamAssigned.teamAssigned || [];
  const existingDefault = existingTeamAssigned.isDefault;
  const currentTeamsAssigned = Object.keys(currentTeamAssigned.teamAssigned);
  let addedTeamsIds = [],
    deletedTeamIds = [];

  currentTeamAssigned.isDefault
    ? !existingDefault && addedTeamsIds.push(orgCode)
    : currentTeamsAssigned.forEach((teamId) => {
        if (existingTeamsAssigned.includes(teamId) === false) {
          addedTeamsIds.push(teamId);
        }
      });
  existingDefault
    ? !currentTeamAssigned.isDefault && deletedTeamIds.push(orgCode)
    : existingTeamsAssigned.forEach((teamId) => {
        if (currentTeamsAssigned.includes(teamId) === false) {
          deletedTeamIds.push(teamId);
        }
      });

  const postBody = {
    isDefault: currentTeamAssigned.isDefault || false,
    addTeams: addedTeamsIds,
    deleteTeams: deletedTeamIds,
  };

  return postBody;
};

export const generateTeamAssignmentMenuBuilderPostData = (
  orgCode,
  existingTeamAssigned,
  currentTeamAssigned,
  sideNavigation,
  bottomNavigation,
  menuUpdatedName,
  cico
) => {
  const existingTeamsAssigned = existingTeamAssigned.teamAssigned || [];
  const existingDefault = existingTeamAssigned.isDefault;
  const currentTeamsAssigned = Object.keys(currentTeamAssigned.teamAssigned);
  let addedTeamsIds = [],
    deletedTeamIds = [];
  currentTeamAssigned.isDefault
    ? !existingDefault && addedTeamsIds.push(orgCode)
    : currentTeamsAssigned.forEach((teamId) => {
        if (existingTeamsAssigned.includes(teamId) === false) {
          addedTeamsIds.push(teamId);
        }
      });
  existingDefault
    ? !currentTeamAssigned.isDefault && deletedTeamIds.push(orgCode)
    : existingTeamsAssigned.forEach((teamId) => {
        if (currentTeamsAssigned.includes(teamId) === false) {
          deletedTeamIds.push(teamId);
        }
      });
  const postBody = {
    sideNavigation: sideNavigation,
    bottomNavigation: bottomNavigation,
    addTeams: addedTeamsIds,
    deleteTeams: deletedTeamIds,
    name: menuUpdatedName,
    config: {
      cico: cico,
    },
  };
  return postBody;
};

export const generateTeamAssignmentLeadControlPostData = (
  orgCode,
  existingTeamAssigned,
  currentTeamAssigned
) => {
  const existingTeamsAssigned = existingTeamAssigned.teamAssigned || [];
  const existingDefault = existingTeamAssigned.isDefault;
  const currentTeamsAssigned = Object.keys(currentTeamAssigned.teamAssigned);
  let addedTeamsIds = [],
    deletedTeamIds = [];
  currentTeamAssigned.isDefault
    ? !existingDefault && addedTeamsIds.push(orgCode)
    : currentTeamsAssigned.forEach((teamId) => {
        if (existingTeamsAssigned.includes(teamId) === false) {
          addedTeamsIds.push(teamId);
        }
      });
  existingDefault
    ? !currentTeamAssigned.isDefault && deletedTeamIds.push(orgCode)
    : existingTeamsAssigned.forEach((teamId) => {
        if (currentTeamsAssigned.includes(teamId) === false) {
          deletedTeamIds.push(teamId);
        }
      });
  const postBody = {
    addTeams: addedTeamsIds,
    deleteTeams: deletedTeamIds,
  };
  return postBody;
};

export const teamViewItems = (status) => {
  return [
    {
      id: casaConstants.CLONE,
      label: casaConstants.CLONE,
    },
    {
      id:
        status === casaConstants.PUBLISHED
          ? casaConstants.UNPUBLISH
          : casaConstants.PUBLISH,
      label:
        status === casaConstants.PUBLISHED
          ? casaConstants.UNPUBLISH
          : casaConstants.PUBLISH,
    },
    {
      id: casaConstants.UNASSIGN,
      label: casaConstants.UNASSIGN,
    },
  ];
};

export const dashboardViewItems = [
  {
    id: casaConstants.CLONE,
    label: casaConstants.CLONE,
  },
  {
    id: casaConstants.DELETE,
    label: casaConstants.DELETE,
  },
  {
    id: casaConstants.UNPUBLISH,
    label: casaConstants.UNPUBLISH,
  },
];
export const launcherItems = [
  {
    id: casaConstants.EDIT,
    label: casaConstants.EDIT,
  },
  {
    id: casaConstants.RENAME,
    label: casaConstants.RENAME,
  },
  {
    id: casaConstants.UNPUBLISH,
    label: casaConstants.UNPUBLISH,
  },
  {
    id: casaConstants.DELETE,
    label: casaConstants.DELETE,
  },
];

export const getTeamsWithMenuAndDashboard = (
  teamsDashboards,
  teamsMappedWithTeamId,
  menuByTeamId
) => {
  const allTeamsDashboards = cloneDeep(teamsDashboards);
  for (let teamId in teamsMappedWithTeamId) {
    let teamIdExists = teamsDashboards.some((team) => team.teamId === teamId);
    if (!teamIdExists && menuByTeamId[teamId]) {
      let newTeam = {
        teamId: teamId,
        homepages: [],
      };
      allTeamsDashboards.push(newTeam);
    }
  }
  return allTeamsDashboards;
};

export const teamsViewCardItems = (status) => {
  return [
    {
      id:
        status === casaConstants.PUBLISHED
          ? casaConstants.UNPUBLISH
          : casaConstants.PUBLISH,
      label:
        status === casaConstants.PUBLISHED
          ? casaConstants.UNPUBLISH
          : casaConstants.PUBLISH,
    },
    {
      id: casaConstants.UNASSIGN,
      label: casaConstants.UNASSIGN,
    },
  ];
};
